import React, { useRef } from "react";
import Benefits from "./Pages/Benefits/Benefits";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home/Home";
import Locations from "./Pages/Locations/Locations";
import Partners from "./Pages/Partners";
import Products from "./Pages/Products/Products";
import Safety from "./Pages/Safety";

import ScrollPageWrapper from "./Components/PageWrapper";
import styled from "styled-components";
import { useIsInViewport } from "./Utils/hooks";
import Nav from "./Pages/Home/Nav/Nav";



import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();
  const homeRef = useRef();
  const benefitsRef = useRef();
  const productsRef = useRef();
  const partnersRef = useRef();
  const safetyRef = useRef();
  const locationsRef = useRef();
  const contactRef = useRef();

  return (
    <QueryClientProvider client={queryClient}>
      <Nav
        refs={[
          homeRef,
          benefitsRef,
          productsRef,
          partnersRef,
          safetyRef,
          locationsRef,
          contactRef,
        ]}
      />
      <StyledApp>
        <ScrollPageWrapper ref={homeRef}>
          <Home
            navRefs={[
              homeRef,
              benefitsRef,
              productsRef,
              partnersRef,
              safetyRef,
              locationsRef,
              contactRef,
            ]}
          />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={benefitsRef}>
          <Benefits contactRef={contactRef} />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={productsRef}>
          <Products />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={partnersRef}>
          <Partners isInView={useIsInViewport(partnersRef)} />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={safetyRef}>
          <Safety />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={locationsRef}>
          <Locations />
        </ScrollPageWrapper>
        <ScrollPageWrapper ref={contactRef}>
          <Contact />
        </ScrollPageWrapper>
      </StyledApp>
    </QueryClientProvider>
  );
}

export default App;

const StyledApp = styled.div`
  max-width: 100%;
  position: absolute;
  top: 0;
;

  p, h1, h2, h3, h4 {
    margin: 0;
  }

  h3: {
    color: #3d3d3d;
  }

  a {
    color: #1e95cf;
    text-decoration: none;
  }

  a:visited {
    color: #1e95cf;
  }

  .link {
    display: inline;
    color: #1e95cf;
    cursor: pointer;
  }

  h1 {
    font-size: 3rem;
    color: #fff;
    font-family: Gotham;
  }

  h2 {
    font-size: 2rem;
    color: #1E95CF;
    font-family: Gotham;
  }

  h3 {
    font-size: 1.4rem;
    color: #3d3d3d;
    font
  }

   h4 {
    font-size: 1.2rem;
    color: #3d3d3d;
  }

  p {
    font-family: Lato;
    font-size: 16px;
    color: #717171;
  }


  .large-text {
    font-size: 24px;
  }

  @media (max-width: 1220px) {
    p,
    input,
    a {
      font-size: 14px;
    }

    h1 {
    font-size: 2rem;

  }

  h2 {
    font-size: 1.5rem;

  }
  h3 {
    font-size: 1.2em;
  }

  h4 {
    font-size: 1em;
  }


  .large-text {
    font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    p,
    a {
      font-size: 10px;
    }

    input, button > p {
      font-size: 12px;
    }

    h3 {
      font-size: 14px;
    }

     h4 {
    font-size: 12px;
  }

  .large-text {
    font-size: 14px;
    }
  }
`;

import React, { useState } from "react";
// import { ReactComponent as USMap } from "../../assets/locations/USMap.svg";

import styled from "styled-components";
// import { AnimatePresence, motion } from "framer-motion";
import GoogleMap from "./GoogleMap";
import MapKey from "./MapKey";

// const mapLocations = {
//   NY: { lat: 40.7, lng: -73.4624 },
//   CT: { lat: 41.6, lng: -73.08 },
//   NJ: { lat: 40.1, lng: -74.41 },
//   PA: { lat: 40.8, lng: -76.5 },
//   TX: { lat: 31.336711659880944, lng: -99 },
//   FL: { lat: 27.66, lng: -81.51 },
// };

const territories = {
  // NY: { lat: 40.7, lng: -73.4624 }, // Las Vegas
  // CT: { lat: 41.6, lng: -73.08 },
  // NJ: { lat: 40.57, lng: -74.41 },
  // PA: { lat: 41.2, lng: -77.19 },
  // FL: [
  //   { lat: 27.66, lng: -81.51 },
  //   { lat: 26.576355173711555, lng: -81.87818037340786 }, // FT Myers
  //   { lat: 28.008087039399957, lng: -82.54130921571286 }, //
  //   { lat: 28.480393312918988, lng: -81.29945273104907 }, // Orlando
  //   { lat: 30.201932706677916, lng: -81.74431720218308 },
  //   { lat: 26.19862450611798, lng: -80.28904039303427 }, // Tamarac
  // ],
  // TX: { lat: 30.336711659880944, lng: -97.65743548683658 },
  NV: [
    { lat: 36.07750425031517, lng: -115.19416777323022 },
    { lat: 39.51105676805527, lng: -119.7454465509261 },
  ],
  AZ: { lat: 33.41248854776775, lng: -111.9818906309579 },
  CA: [
    { lat: 38.655591128908526, lng: -121.48905093068814 },
    { lat: 33.7958113332825, lng: -117.85865045767203 },
  ],
};

const flattenedLocations = (() => {
  const res = [];
  for (let state in territories) {
    res.push(territories[state]);
  }
  return res.flat();
})();

// const transition = { duration: 0.8, ease: "easeInOut" };

function Locations() {
  const [selectedState, setSelectedState] = useState("");
  // const [location, setLocation] = useState({
  //   lat: 39.21818430912803,
  //   lng: -95.03577714937136,
  // });

  // const handleStateClick = (e) => {
  //   const territory = e.target.closest("g")?.id;
  //   const location = mapLocations[territory]
  //     ? mapLocations[territory]
  //     : territories[territory];
  //   if (location) setSelectedState(territory);
  //   setLocation(location);
  // };

  return (
    <section className="page-content-wrapper" style={{ maxHeight: "100vh" }}>
      <StyledLocations>
        <div className="header-wrapper" style={{ marginBottom: "2em" }}>
          <h2 className="header-blue">Our Service Locations</h2>
        </div>

        <div className="maps-wrapper">
          {/* <AnimatePresence>
            {selectedState ? (
              <motion.div
                key="map"
                className="map-wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={transition}
              >
                <GoogleMap
                  selectedState={selectedState}
                  setSelectedState={setSelectedState}
                  location={location}
                  allLocations={flattenedLocations}
                  style={{}}
                />
              </motion.div>
            ) : (
              <motion.div
                key={"svg"}
                className="map-wrapper"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={transition}
              >
                <USMap
                  className="US-map"
                  height="70vh"
                  onClick={(e) => handleStateClick(e)}
                />
              </motion.div>
            )}
          </AnimatePresence> */}
          <div className="map-wrapper">
            <MapKey />
            <GoogleMap
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              // location={location}
              allLocations={flattenedLocations}
              style={{}}
            />
          </div>
        </div>
      </StyledLocations>
    </section>
  );
}

export default Locations;

const StyledLocations = styled.div`
  min-height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  max-width: 100%;

  .maps-wrapper {
    width: 100%;
    min-height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .map-wrapper {
    position: absolute;
    width: 100%;
    max-width: 1200px;
    min-height: 70vh;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .US-map {
    max-height: 100%;
    max-width: 100%;
    margin: auto;
  }

  g#CA,
  g#NV,
  g#NY,
  g#NJ,
  g#FL,
  g#AZ,
  g#PA,
  g#CT,
  g#TX {
    cursor: pointer;
  }

  g#NY:hover,
  g#NJ:hover,
  g#TX:hover,
  g#FL:hover,
  g#AZ:hover,
  g#PA:hover,
  g#CT:hover,
  g#NV:hover,
  g#CA:hover {
    opacity: 0.6;
  }
`;

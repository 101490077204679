const RI = [
  { lng: -71.860513, lat: 41.320248 },
  { lng: -71.86277199999999, lat: 41.309791 },
  { lng: -71.785957, lat: 41.325739 },
  { lng: -71.7292965762004, lat: 41.333284002713796 },
  { lng: -71.70163099999999, lat: 41.336968 },
  { lng: -71.624505, lat: 41.36087 },
  { lng: -71.555381, lat: 41.373315999999996 },
  { lng: -71.483295, lat: 41.371722 },
  { lng: -71.455371, lat: 41.407962 },
  { lng: -71.428652, lat: 41.454158 },
  { lng: -71.41721279148369, lat: 41.45603131277439 },
  { lng: -71.389284, lat: 41.460605 },
  { lng: -71.351096, lat: 41.450801999999996 },
  { lng: -71.312694, lat: 41.451402 },
  { lng: -71.285639, lat: 41.487805 },
  { lng: -71.245992, lat: 41.481302 },
  { lng: -71.2398044238189, lat: 41.4785720701705 },
  { lng: -71.21267275910739, lat: 41.466601705903095 },
  { lng: -71.19301999999999, lat: 41.457930999999995 },
  { lng: -71.140224, lat: 41.485855 },
  { lng: -71.12057, lat: 41.497448 },
  { lng: -71.137492, lat: 41.602561 },
  { lng: -71.132888, lat: 41.660101999999995 },
  { lng: -71.19564, lat: 41.67509 },
  { lng: -71.2013271875249, lat: 41.6817683282027 },
  { lng: -71.20860053289509, lat: 41.6903092436027 },
  { lng: -71.261392, lat: 41.752300999999996 },
  { lng: -71.31740200793121, lat: 41.7772561089687 },
  { lng: -71.329396, lat: 41.7826 },
  { lng: -71.339597, lat: 41.832 },
  { lng: -71.339298, lat: 41.893398999999995 },
  { lng: -71.38170000000001, lat: 41.893198999999996 },
  { lng: -71.381461, lat: 41.952141 },
  { lng: -71.3814313468931, lat: 41.9850841191295 },
  { lng: -71.381401, lat: 42.018798 },
  { lng: -71.4580809945561, lat: 42.0168788251287 },
  { lng: -71.4982240131247, lat: 42.0158741110185 },
  { lng: -71.559439, lat: 42.014342 },
  { lng: -71.59109652763101, lat: 42.01351334355719 },
  { lng: -71.6066944190753, lat: 42.013105058570794 },
  { lng: -71.79924199999999, lat: 42.008064999999995 },
  { lng: -71.792767, lat: 41.807001 },
  { lng: -71.78969543168239, lat: 41.7251982448091 },
  { lng: -71.789678, lat: 41.724734 },
  { lng: -71.789464590839, lat: 41.6400171907069 },
  { lng: -71.789356, lat: 41.59691 },
  { lng: -71.7893586727423, lat: 41.5968521603427 },
  { lng: -71.79170363082619, lat: 41.5461059382128 },
  { lng: -71.79768299999999, lat: 41.416709 },
  { lng: -71.839649, lat: 41.412119 },
  { lng: -71.835951, lat: 41.353935 },
  { lng: -71.860513, lat: 41.320248 },
];

export default RI;

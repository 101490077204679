import React from "react";
// import Button from "../../Components/Button";
import GoogleMapReact from "google-map-react";
import stateBorders from "./stateBorders";
import { pins, roofPins } from "./pins";

import Pin from "../../assets/locations/Pin.svg";
import RoofPin from "../../assets/locations/RoofPin.svg";

const RADIUS = 100 * 1609.344; // Convert 100 miles to meters

const mapAreaOptions = {
  strokeColor: "#336699",
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: "#336699",
  fillOpacity: 0.2,
};

function drawCircle({ map, maps }, center) {
  const circle = new maps.Circle({
    ...mapAreaOptions,
    center,
    radius: RADIUS,
    map,
  });

  // For Phoenix
  if (center.lat === 33.41248854776775) {
    circle.fillColor = "#94dbff";
    circle.strokeColor = "#94dbff";
  }
  // For Las Vegas
  if (center.lat === 36.07750425031517 || center.lat === 39.51105676805527) {
    circle.fillColor = "grey";
    circle.strokeColor = "grey";
  }
}

function drawState({ map, maps }, paths) {
  new maps.Polygon({
    paths,
    map,
    shadow: "4px 4px 4px black",
    ...mapAreaOptions,
    strokeColor: "grey",
    fillColor: "grey",
  });
}

function drawPin({ map, maps }, position, icon = Pin) {
  // Pin.scale = 0.5;
  new maps.Marker({
    position,
    icon,
    map,
    height: "10px",
  });
}

const GoogleMap = ({
  // setSelectedState,
  location = {
    lat: 37.5,
    lng: -95.03577714937136,
  },
  allLocations,
  // selectedState,
}) => {
  return (
    <>
      {/* <Button
        label="Go Back"
        style={{ marginBottom: "1em" }}
        onClick={() => setSelectedState()}
      /> */}
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCR7yTvbSisKRJcpb20uNWQ_wZLWuCive8" }}
        defaultCenter={Array.isArray(location) ? location[0] : location}
        // SETTINGS FOR CLICKING ON THE SVG MAP
        // defaultZoom={
        //   selectedState === "TX" || selectedState === "FL"
        //     ? 6
        //     : selectedState === "PA"
        //     ? 7
        //     : 8
        // }
        defaultZoom={4.2}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => {
          // Draws all locations circles
          allLocations.forEach((center) => drawCircle({ map, maps }, center));

          // Draws state borders
          stateBorders.forEach((paths) => {
            drawState({ map, maps }, paths);
          });

          // Draws Pins
          pins.forEach((position) => {
            drawPin({ map, maps }, position);
          });

          roofPins.forEach((position) => {
            drawPin({ map, maps }, position, RoofPin);
          });

          //Handles map center if there are multiple territories in the state
          if (Array.isArray(location)) {
            const bounds = new maps.LatLngBounds();
            location.forEach((coords) => bounds.extend(coords));
            map.fitBounds(bounds);
          }
        }}
      />
    </>
  );
};

export default GoogleMap;
